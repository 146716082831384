.footer-main-container{
    background-color: rgb(71, 70, 70);
    padding: 10px;
}
.footer-container{
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-evenly;
    gap: 10px;
    cursor: pointer;
}
.logo-footer img{
    object-fit: cover;
    width: 100px;
    max-width: 120px;
    border-radius: 50px;
}
.footer-links h2{
    text-decoration: underline;
    color: #dddada;
    text-align: center;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.footer-links h2:hover{
    color: black;
}
.footer-links h4, .footer-link{
    font-family: "Ubuntu", sans-serif;
    font-weight: 350;
    font-style: normal;
    transition: width 0.2s height 0.3s ease-in 0.7s;
    color: #000000;
    text-decoration: none;
}
.footer-links h4:hover{
    color: beige;
    font-weight: 400;
}
.footer-link:hover{
    color: beige;
    font-weight: 400
}
.social-media-icons{
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    gap: 30px;
}
.social-icon {
    font-size: 1.8rem;
    color: rgb(18, 27, 44);
    border-radius: 50%;
    height: 2.2rem;
    width: 2.2rem;
    background-color: transparent;
    transition: color 0.3s ease 0.3s, font-size 0.3s ease 0.3s; /* Set delay on both color and font-size transitions */
}

.social-icon:hover {
    color: rgb(255, 166, 0);
    font-size: 1.4rem; 
}


.footer-copywrite{
    text-decoration: overline;
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    font-style: normal;
    width: 100%;
}

/* responsiveness */

@media screen and (max-width:480px) {
    .footer-container{
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
    }
    .logo-footer img{
        object-fit: cover;
        width: 100%;
        max-width: 100px;
        border-radius: 50px;
    }
    .footer-links h2{
        text-decoration: underline;
        color: #000000;
    }
}

/* for tablets devices */

@media screen and (min-width:481px) and (max-width:769px) {
    .footer-container{
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
    }
    .logo-footer img{
        object-fit: cover;
        width: 100%;
        max-width: 100px;
        border-radius: 50px;
    }
    .footer-links h2{
        text-decoration: underline;
        color: #000000;
    }
}
