.blog-container{
    background-color: black;
    height: 100%;
}
.blog-image{
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    padding: 70px 52px;
    background-image: url(https://cdn.shopify.com/s/files/1/0570/3106/5768/files/pexels-lisa-fotios-1652109.jpg?v=1710925895);
}
.blog-name{
    font-size: 10rem;
    font-family: "Lato", sans-serif;
    color: rgb(53, 26, 1);
    font-weight: bolder;
}
.blog-last-name{
    font-size: 2.5rem;
    font-family: "Lato", sans-serif;
    color: rgb(0, 0, 0);
}
.blog-image img{
    height: 500px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.blog-heading{
    padding: 10px;
    margin-left: 5rem;
}
.rest-of-word{
    color: rgb(241, 124, 14);
    font-family: "Lato", sans-serif;
}
.big-b {
    font-size: 4rem;
    font-weight: bold; 
    color: #fff;
}
.blog-paragraph{
    color: #fff;
    margin: 0rem 5rem;
    width: 100%;
    max-width: 700px;
    background-color: #3E3939;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.blog-paragraph h3{
    font-family: "Lato", sans-serif;
    padding: 15px;
    font-weight: bolder;
}
.blog-paragraph p{
    line-height: 1.7;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    padding: 15px;
}

/* media queries */
@media screen and (max-width: 768px){
    .blog-image{
        display: flex;
        flex-direction: column-reverse;
        background-image: url(https://cdn.shopify.com/s/files/1/0570/3106/5768/files/pexels-lisa-fotios-1652109.jpg?v=1710925895);
    }
    .blog-image img{
        height: 500px;
        text-align: center;
        width: 100%;
        object-fit: cover;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .blog-name{
        color: rgb(238, 220, 202);
    }
    .blog-last-name{
        color: rgb(199, 194, 194);
    }
    .blog-heading{
        padding: 10px;
        margin-left: 1rem;
    }
    .blog-paragraph{
        margin: 0rem 2rem;
        width: 90%;
        max-width: 300px;
        background-color: #3E3939;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
}
/* media queries */
@media screen and (max-width: 768px){
    .blog-paragraph{
        margin: 0rem 2rem;
        width: 90%;
        max-width: 500px;
        background-color: #3E3939;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
}