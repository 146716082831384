.nav-container{
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    gap: 3rem;
    transition: background-color 0.3s ease; 
    background-color: rgba(71, 71, 71, 0.7); 
    width: 100%;
    z-index: 1000;
}
.nav-container:hover {
    background-color: rgba(102, 100, 100, 0.7); 
    cursor: pointer; 
}
.nav-logo{
    padding: 10px;
}
.nav-logo img{
    width: 100%;
    max-width: 50px;
    border-radius: 50px;
    object-fit: cover;
}
.nav-list{
    padding-right: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    gap: 10rem;
}
.center-links{
    display: flex;
    justify-content: center;
    gap: 3rem;
}
.center-links ul{
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}
.center-links a{
    border: none;
    text-decoration: none;
    color: #fff;
    font-size: 1.2rem;
    transition: ease-in-out 3s;
}
.center-links a:hover{
    color: #080808;
    font-size: 1.3rem;
}
.nav-list ul{
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}
.nav-list li{
    float: left;
}
.contact-links a{
    text-decoration: none;
    border: 1.0px solid #0e0d0d;
    background-color: transparent;
    font-size: 1.3rem;
    font-family: "Lato", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: #fff;
    transition: ease-in-out 0.5s;
}
.contact-links a:hover{
    background-color: #000;    /* opacity: 1; */
    color: #ffffff;
    cursor: pointer;
}
.menu-icon{
    display: none;
}
.nav-list.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
    width: 150px;
    z-index: 1002;
  }

  /* using reponsiveness media query*/

  @media(max-width: 768px) {
    .nav-logo img{
        width: 100%;
        max-width: 45px;
    }
    .nav-list{
        display: none;
        flex-direction: column;
    }
    .menu-icon{
        display: block;
        color: #fff;
        z-index: 1003;
        padding: 10px;
    }
    .nav-list.open{
        display: flex;
        cursor: pointer;
        margin: 12px;
        height: 130px;
        background-color: #8a8686;
        box-shadow: rgba(121, 114, 114, 0.16) 0px 1px 4px;
        flex-direction: column;
        z-index: 1002;
    }
    .center-links{
        flex-direction: column;
        gap: 1rem;
        padding: 10px;
    }
    .nav-list a {
        color: #000;
        margin: 1rem;
        font-size: 1.1rem;
        border: none;
        z-index: 1002;
    }
    .nav-list ul{
        flex-direction: column;
        gap: 1rem;
        padding: 10px;
    }
  }

