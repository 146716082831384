/* VideoBackground.css */

.video-background-wrapper {
  position: relative;
  overflow: hidden;
  height: 100vh; 
  z-index: 1;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; 
}


/* Home css */
.Home-container {
  position: absolute; 
  top: 60%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  text-align: center; 
  color: white; 
  z-index: 1002;
}
.Home-container h2{
  font-size: 5.5rem;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #fff;
  transition: ease-in-out 0.5s;
}
.home-btn {
  margin-top: 6%; 
}

.home-btn button {
  padding: 10px 20px; 
  font-size: 1.4rem;
  background-color: #000000;
  color: white; 
  border: none; 
  border-radius: 5px; 
  cursor: pointer; 
  transition: background-color 0.3s; 
  transition: ease-in-out 0.5s;
}

.home-btn button:hover {
  background-color: #03104b;
  border-radius: 10px;
}



@media (max-width: 768px) {
  .Home-container h2 {
      font-size: 3.5rem;
  }
}


/* About us section */


.About-section{
  background-color: #000;
}
.About-container{
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-around;
}
.About-container img {
  margin-top: 5%;
  width: 100%;
  max-width: 500px;
}
.About-headings h2{
  text-align: center;
  margin-top: 2rem;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #fff;
  font-size: 4rem;
  text-decoration: underline;
}
.About-headings p {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #ebeaea;
  line-height: 1.7;
  width: 100%;
  max-width: 600px;
  font-size: 1.2rem;
}

/* mobile responsiveness */

@media screen and (max-width:480px){
  .About-container{
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
  }
  .About-container img {
    margin-top: 10%;
    width: 100%;
    max-width: 300px;
  }
  .About-headings h2{
    margin-top: 1rem;
    text-align: center;
    font-weight: 400;
    font-size: 2.5rem;
  }
  .About-headings p {
    font-weight: 300;
    line-height: 1.7;
    max-width: 330px;
  }

}

  /* TAblet responsiveness */

  @media screen and (min-width:480px) and (max-width:768px){
    .About-container{
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
    }
    .About-container img {
      margin-top: 10%;
      width: 100%;
      max-width: 300px;
    }
    .About-headings h2{
      margin-top: 1rem;
      text-align: center;
      font-weight: 500;
      font-size: 2.5rem;
    }
    .About-headings p {
      font-weight: 300;
      line-height: 1.7;
      max-width: 450px;
    }
  
  }
/* larger tablets */

@media screen and (min-width:769px) and (max-width:1025px){
.About-container{
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
}
.About-container img {
  max-width: 450px;
}
.About-headings h2{
  font-weight: 500;
  font-size: 2.5rem;
}
.About-headings p {
  font-weight: 300;
  line-height: 1.5;
  max-width: 600px;
}

}