.Retail-container{
    /* background-color: black; */
    padding: 5px;
}
.Production-heading h2{
    text-align: center;
    margin-top: 3rem;
    font-family: "Lato", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: #fff;
    font-size: 2.8rem;
    text-decoration: underline;
}
.Production-heading h4{
    text-align: center;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #fff;
    font-size: 1.4rem;
}
.production-cards{
    display: flex;
    align-items: center;
    justify-content: space-between;
    justify-content: center;
    gap: 50px;
    background-color: rgb(24, 24, 24);
    padding: 3.5rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.production-card{
    width: 100%;
    height: 100lvh;
}

.prodution-img{
    width: 100%;
    max-width: 400px;
    max-height: 60%;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: rgba(94, 93, 93, 0.15) 0px 48px 100px 0px;
}
.production-captions{
    background-color: #000;
    margin-top: 2rem;
    border-radius: 10px;
    height: 200px;
    width: 100%;
    color: #d4d4d4;
    line-height: 1.5;
    text-align: center;
    font-family: "Ubuntu", sans-serif;
    font-weight: 300;
    font-style: normal;
    box-shadow: rgba(33, 32, 37, 0.15) 0px 48px 100px 0px;
}

/* Mobile responsive design */

@media only screen and (max-width: 480px) { 
    .Production-heading h2 {
        text-align: center;
        margin-top: 5%;
        font-size: 2rem; 
        font-weight: 400;
    }
    .Production-heading h4 {
        text-align: center;
        font-size: 1.2rem;
    }
    .production-cards {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        padding: 1rem;
        gap: 0;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .production-img { 
        width: 100%;
        max-width: 360px;
    }
    .production-captions {
        background-color: #000;
        margin-top: 1rem;
        height: auto;
        width: 100%;
    }
    .production-card {
        width: 100%;
        height: auto;
        margin: 0 auto;
    }
}

/* Tablet responsive design */

@media only screen and (min-width: 481px) and (max-width: 768px) { 
    .Production-heading h2 {
        text-align: center;
        margin-top: 5%;
        font-size: 2rem; 
        font-weight: 400;
    }
    .Production-heading h4 {
        text-align: center;
        font-size: 1.2rem;
    }
    .production-cards {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        padding: 1rem;
        gap: 0;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .production-img { 
        width: 100%;
    }
    .production-captions {
        background-color: #000;
        margin-top: 1rem;
        height: auto;
        width: 100%;
    }
    .production-card {
        width: 100%;
        height: auto;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1025px) {
    .Production-heading h2 {
        text-align: center;
        margin-top: 2rem; /* Reduced margin for a cleaner look */
        font-size: 2rem; /* Adjusted size for better readability */
        font-weight: 400;
    }
    .Production-heading h4 {
        text-align: center;
        font-size: 1.2rem; 
    }
    .production-cards {
        display: flex;
        flex-wrap: wrap; 
        justify-content: center; 
        align-items: flex-start; 
        padding: 1.2rem; 
        gap: 4rem; 
    }
    .production-card {
        width: 100%; 
        max-width: 390px;
        margin: 0 auto; 
        height: 600px; 
        /* overflow: hidden;  */
    }
    .production-img { 
        width: 100%; 
        height: auto; 
        object-fit: cover; 
    }
    .production-captions {
        background-color: #000;
        padding: 0.5rem; 
        margin-top: 0.5rem; 
        height: auto; 
        width: 100%; 
        text-align: center; 
        font-size: 0.9rem;
        line-height: 1.5; 
    }
}
    /* @media only screen and (max-width: 1026px) {
        .production-card {
            width: 100%; 
            max-width: 390px;
            margin: 0 auto; 
            height: 1600px; 
        }
        .production-img { 
            width: 100%; 
            height: auto; 
            object-fit: cover; 
        } */

