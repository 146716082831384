/* .Contact-container{
    background-color: #000000;
} */
.contact-heading h2{
    text-align: center;
    margin-top: 0rem;
    font-family: "Lato", sans-serif;
    font-weight: 500;
    font-style: normal;
    color: #fff;
    font-size: 2.8rem;
    text-decoration: underline;
}
.contact-heading p{
    text-align: center;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #fff;
    font-size: 1.2rem;
    width: 100%;
    line-height: 1.5;
}
.contact-card{
    background-color: rgb(24, 24, 24);
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
}
.contact-information{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.contact-info{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 20px;
    width: 100%;
    max-width: 300px;
    margin: 1rem;
    gap: 1rem;
}
.contact_head-info h4{
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #302e2e;
}
.form-container{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 100%;
    max-width: 400px;
    border-radius: 20px;
    padding: 40px;
}
.form-container h3 ,p{
    text-align: center;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.input-form{
    padding: 10px;
}
.input-name-container{
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-around;
    padding: 2px;
}
.information-inputs{
    flex-direction: column;
    align-items: start;
    justify-content: center;
    justify-content: space-around;
    padding: 2px;
}
.text-inputs{
    padding: 12px 2px;
}
.contact-info-btn button{
    display: block;
    align-items: center;
    text-align: center;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.2rem;
    background-color: #000;
    color: #fff;
    width: 50%;
    max-width: 500px;
    margin-top: 5%;
    border: 0px solid;
    padding: 6px;
    transition: ease-in-out 0.5s;
}
.contact-info-btn button:hover{
    background-color: rgb(0, 6, 56);
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
}
input{
    font-size: 0.8rem;
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-style: normal;
    border: 1px solid;
    width: 80%;
    padding: 10px;
    background-color: transparent;
    color: rgb(26, 26, 63);
}
textarea {
    font-size: 0.8rem;
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-style: normal;
    border: 1px solid;
    width: 80%;
    height: auto;
    padding: 10px;
    background-color: transparent;
    color: rgb(26, 26, 63);
    resize: none;
}
textarea:focus, input:focus{
    outline: none;
}

@media screen and (max-width:480px) {
    .contact-heading h2{
        font-weight: 400;
        font-size: 2.4rem;
    }
    .contact-heading p{
        text-align: center;
        color: #fff;
        font-size: 1rem;
    }
    .contact-card{
        background-color: rgb(24, 24, 24);
        padding: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .form-container{
        max-width: 300px;
        border-radius: 10px;
        padding: 15px;
    }
}

/* larger phones */
@media screen and (min-width:481px) and (max-width:768px){
    .contact-heading h2{
        font-weight: 400;
        font-size: 2.4rem;
    }
    .contact-heading p{
        text-align: center;
        color: #fff;
        font-size: 1rem;
    }
    .contact-card{
        background-color: rgb(24, 24, 24);
        padding: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .form-container{
        max-width: 300px;
        border-radius: 10px;
        padding: 15px;
    }
}

/* larger phones */
@media screen and (min-width:769px) and (max-width:1025px){
    .contact-heading h2{
        font-weight: 400;
        font-size: 2.4rem;
    }
    .contact-heading p{
        text-align: center;
        color: #fff;
        font-size: 1rem;
    }
    .contact-card{
        background-color: rgb(24, 24, 24);
        padding: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: space-around;
    }
    .form-container{
        max-width: 320px;
        border-radius: 10px;
        padding: 15px;
    }
}
